import { ScheduleType } from "../../utils/schedule/calculateSchedule";

export const exampleRecurringSchedule: ScheduleType = {
  recurringSchedule: {
    weekly: {
      monday: [
        {
          start: "08:00",
          end: "21:00",
          status: "JIT",
          frequency: "15 minutes",
        },
        { start: "10:00", status: "scheduled" },
        { start: "14:00", status: "scheduled" },
        { start: "17:00", status: "scheduled" },
      ],
      tuesday: [],
      wednesday: [
        {
          start: "08:00",
          end: "21:00",
          status: "JIT",
          frequency: "15 minutes",
        },
        { start: "10:00", status: "scheduled" },
        { start: "14:00", status: "scheduled" },
        { start: "17:00", status: "scheduled" },
      ],
      thursday: [
        {
          start: "08:00",
          end: "21:00",
          status: "JIT",
          frequency: "15 minutes",
        },
        { start: "10:00", status: "scheduled" },
        { start: "14:00", status: "scheduled" },
        { start: "17:00", status: "scheduled" },
      ],
      friday: [
        {
          start: "08:00",
          end: "21:00",
          status: "JIT",
          frequency: "15 minutes",
        },
        { start: "10:00", status: "scheduled" },
        { start: "14:00", status: "scheduled" },
        { start: "17:00", status: "scheduled" },
      ],
      saturday: [
        {
          start: "08:00",
          end: "21:00",
          status: "JIT",
          frequency: "15 minutes",
        },
        { start: "10:00", status: "scheduled" },
        { start: "14:00", status: "scheduled" },
        { start: "17:00", status: "scheduled" },
      ],
      sunday: [
        {
          start: "08:00",
          end: "21:00",
          status: "JIT",
          frequency: "15 minutes",
        },
        { start: "10:00", status: "scheduled" },
        { start: "14:00", status: "scheduled" },
        { start: "17:00", status: "scheduled" },
      ],
    },
  },

  options: {
    totalDatesToDisplay: 2,
    inTimezone: "EST",
    mode: "recurring",
  },
};
