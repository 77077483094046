import emailMisspelled, { top100 } from "email-misspelled";
import {
  WebinarRegistrationFormError,
  WebinarRegistrationFormState,
} from "./CCWebinarRegistrationModal1";
import { isValidEmail } from "@/utils/isValidEmail";
import { SetFeatureFlags } from "@/types/decisions";
import { Track } from "components/trackEvent";
import { useState } from "react";
import Requires from "internal_components/Requires";

const WebinarEmailInput = ({
  handleChange,
  formError,
  formState,
  setFormError,
  clientSideId,
  featureFlagHash,
  featureFlags,
  track,
  setFormState,
}: {
  handleChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  formError: WebinarRegistrationFormError;
  formState: WebinarRegistrationFormState;
  setFormError: React.Dispatch<
    React.SetStateAction<WebinarRegistrationFormError>
  >;
  clientSideId: string;
  featureFlagHash?: string;
  featureFlags: SetFeatureFlags;
  track: Track;
  setFormState: React.Dispatch<
    React.SetStateAction<WebinarRegistrationFormState>
  >;
}) => {
  const [emailSuggestion, setEmailSuggestion] = useState("");

  const selectEmailSuggestion = () => {
    track(
      {
        action: "page_interaction",
        clientSubmissionUniqueId: clientSideId,
        meta: {
          interaction: "user_selected_email_suggestion",
          resourceType: "email",
        },
      },
      "xhr"
    );

    setFormState({ ...formState, email: emailSuggestion });
    setEmailSuggestion("");
  };

  const validateEmail = () => {
    if (!isValidEmail(formState.email)) {
      setFormError({ ...formError, email: true });
    } else {
      const emailChecker = emailMisspelled({ domains: top100 });

      const results = emailChecker(formState.email);

      if (results && results.length > 0) {
        setEmailSuggestion(results[0].corrected);
        track(
          {
            clientSubmissionUniqueId: clientSideId,
            action: "content_view",
            clientSideDecisionId: featureFlagHash,
            featureFlags,
            meta: {
              resourceType: "email_suggestion",
            },
          },
          "xhr"
        );
      }

      setFormError({ ...formError, email: false });
    }
  };

  return (
    <>
      <div>
        <label className="label-style" htmlFor="email">
          Email
        </label>
        <input
          onBlur={validateEmail}
          className={`${
            formError.email ? "input-style-error" : "input-style"
          } h-16 text-xl`}
          id="email"
          type="email"
          autoCapitalize="off"
          value={formState.email}
          placeholder="Email"
          onChange={handleChange}
          name="email"
        />
      </div>
      <Requires value={emailSuggestion}>
        <div
          onClick={selectEmailSuggestion}
          className="text-sm bg-orange-200 hover:bg-orange-300 cursor-pointer text-orange-800 rounded-full shadow p-2 text-center"
        >
          Did you mean {emailSuggestion}?
        </div>
      </Requires>
    </>
  );
};

export default WebinarEmailInput;
