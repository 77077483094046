import { weekdayMonthDisplayDate } from "@/utils/dates/weekdayMonthDisplay";

function formatWebinarDate(date: Date) {
  const today = new Date();
  const tomorrow = new Date(today);
  tomorrow.setDate(tomorrow.getDate() + 1);

  if (date.toDateString() === today.toDateString()) {
    return (
      "Today, " +
      date.toLocaleDateString("en-US", { month: "long", day: "numeric" }) +
      " @ " +
      date.toLocaleTimeString("en-US", {
        hour: "2-digit",
        minute: "2-digit",
      })
    );
  } else if (date.toDateString() === tomorrow.toDateString()) {
    return (
      "Tomorrow, " +
      date.toLocaleDateString("en-US", { month: "long", day: "numeric" }) +
      " @ " +
      date.toLocaleTimeString("en-US", {
        hour: "2-digit",
        minute: "2-digit",
      })
    );
  } else {
    return (
      weekdayMonthDisplayDate(date) +
      " @ " +
      date.toLocaleTimeString([], {
        hour: "numeric",
        minute: "2-digit",
      })
    );
  }
}

export default formatWebinarDate;
