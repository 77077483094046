import { DateTime } from "luxon";
import { ScheduleType, daysOfWeek, copyDate } from "./calculateSchedule";

const getStartAndEndDates = (
  date: Date,
  inTimezone: "local" | "EST",
  start: string,
  end?: string,
  userTimezone?: string
) => {
  let startDate;
  let endDate;

  if (inTimezone === "local") {
    startDate = DateTime.fromJSDate(date).setZone(userTimezone, {
      keepLocalTime: true,
    });
    endDate = DateTime.fromJSDate(date).setZone(userTimezone, {
      keepLocalTime: true,
    });
  } else {
    startDate = DateTime.fromJSDate(date).setZone("America/New_York");
    endDate = DateTime.fromJSDate(date).setZone("America/New_York");
  }

  const [startHour, startMinute] = start.split(":");
  startDate = startDate.set({
    hour: +startHour,
    minute: +startMinute,
    second: 0,
    millisecond: 0,
  });

  if (end) {
    const [endHour, endMinute] = end.split(":");
    endDate = endDate.set({
      hour: +endHour,
      minute: +endMinute,
      second: 0,
      millisecond: 0,
    });
  }

  return [startDate, endDate] as [DateTimeWithType, DateTimeWithType];
};

type DateTimeWithType = DateTime & { type?: "scheduled" | "JIT" };
export type DateWithType = Date & { type?: "scheduled" | "JIT" };

export function calculateRecurringSchedule(
  date: Date,
  schedule: ScheduleType,
  userTimezone: string
) {
  {
    const maxJITWebinarsToShow = 1;
    let shouldAddJITDates = true;

    const foundDates: Array<DateTimeWithType> = [];
    let jitDates = 0;

    const now = DateTime.fromJSDate(date);

    const { totalDatesToDisplay = 1 } = schedule.options;
    const timezone = schedule.options.inTimezone || "EST";

    while (foundDates.length < totalDatesToDisplay) {
      let currentDayOfWeek = daysOfWeek[date.getDay()];

      const dailySchedule =
        schedule.recurringSchedule!.weekly[currentDayOfWeek];

      // ensure we process JIT dates, then scheduled dates
      // in the event a scheduled date is the same as a JIT date,
      // we replace it with a scheduled date as the schedule ddate
      // trumps the JIT date for event tracking purposes
      const sortedDailySchedule = dailySchedule.sort((a, b) => {
        if (a.status === "JIT" && b.status === "scheduled") {
          return -1;
        } else if (a.status === "scheduled" && b.status === "JIT") {
          return 1;
        } else {
          return 0;
        }
      });

      for (const entry of sortedDailySchedule) {
        let end, start, frequency, status;
        if (entry.status === "JIT") {
          start = entry.start;
          end = entry.end;
          frequency = entry.frequency;
          status = entry.status;
        } else {
          start = entry.start;
          status = entry.status;
        }

        const [startDate, endDate] = getStartAndEndDates(
          date,
          timezone,
          start,
          end,
          userTimezone
        );

        switch (status) {
          case "scheduled":
            if (startDate < now) {
              continue;
            } // already happened
            if (foundDates.length >= totalDatesToDisplay) {
              continue;
            } // already have enough dates

            const startDateISO = `${startDate.toISODate()} ${startDate.toISOTime()}`;
            const foundDate = foundDates[0];
            const foundDateISO = `${foundDate?.toISODate()} ${foundDate?.toISOTime()}`;
            const datesEqual =
              (foundDate && startDate.equals(foundDate)) ||
              startDateISO === foundDateISO;

            if (foundDates[0] && datesEqual) {
              // replace the first date with the scheduled date because schedule trumps JIT
              startDate.type = "scheduled";
              foundDates[0] = startDate;

              // if the first date is the same as a JIT date that has already been added,
              // don't duplicate it, but continue so we add another scheduled date in the future
              continue;
            } else {
              startDate.type = "scheduled";
              foundDates.push(startDate);
            }

            // if the first date is scheduled, we don't want to show JIT dates
            shouldAddJITDates = false;
            break;
          case "JIT":
            if (!shouldAddJITDates) {
              continue;
            }
            if (jitDates >= maxJITWebinarsToShow) {
              continue;
            } // already have enough JIT dates

            // if the start date has already passed, we don't add it (we only add JIT dates in the future)
            if (startDate > now) {
              const date = DateTime.fromJSDate(
                copyDate(startDate.toJSDate())
              ) as DateTimeWithType;
              date.type = "JIT";
              foundDates.push(date);
              jitDates += 1;
            }

            // if there's no recurring frequency, our job is done for this round
            if (!frequency) {
              continue;
            }

            let currentJITDate = copyDate(startDate.toJSDate());

            while (
              jitDates < maxJITWebinarsToShow &&
              currentJITDate.getTime() < endDate.toJSDate().getTime()
            ) {
              const jitDate = copyDate(currentJITDate);

              const [frequencyNum, frequencyUnits] = frequency?.split(" ");

              // step the currentJITDate forward by the frequency
              if (frequencyUnits === "minutes") {
                jitDate.setMinutes(currentJITDate.getMinutes() + +frequencyNum);
              }
              if (frequencyUnits === "hours") {
                jitDate.setHours(currentJITDate.getHours() + +frequencyNum);
              }

              if (jitDates < maxJITWebinarsToShow) {
                if (jitDate.getTime() > now.toJSDate().getTime()) {
                  const date = DateTime.fromJSDate(
                    copyDate(jitDate)
                  ) as DateTimeWithType;
                  date.type = "JIT";
                  foundDates.push(date);
                  jitDates += 1;
                }

                currentJITDate = copyDate(jitDate);
              }
            }
        }
      }

      date.setHours(0);
      date.setDate(date.getDate() + 1);
    }

    return foundDates.map((d) => {
      const date = d.toJSDate() as DateWithType;
      date.type = d.type;
      return date;
    });
  }
}
