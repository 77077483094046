import { ReactNode, useEffect } from "react";

type ModalProps = {
  children: ReactNode;
  isOpen: boolean;
  onClose: () => void;
  timedClose?: number;
};
export const Modal = ({
  children,
  isOpen,
  onClose,
  timedClose,
}: ModalProps) => {
  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "unset";
    }

    return () => {
      document.body.style.overflow = "unset";
    };
  }, [isOpen]);

  if (timedClose && timedClose > 0 && isOpen) {
    setTimeout(() => {
      onClose();
    }, timedClose * 1000);
  }

  return (
    // z-[60] is used because tailwind defaults only go up to z-50, which is what the nav bar is set to, and modals have to overlap that
    <div
      className={
        isOpen
          ? "fixed top-0 left-0 w-screen h-screen bg-slate-600 bg-opacity-50 flex justify-center items-center z-[60]"
          : "hidden"
      }
    >
      <div className="relative w-full max-w-2xl m-auto rounded border-2 shadow-lg">
        <div className="absolute top-0 right-0 p-4">
          <button onClick={onClose} data-identifier="modal-close">
            <div className="w-8 h-8 rounded-full bg-white flex justify-center items-center">
              <svg
                className="w-6 h-6"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </div>
          </button>
        </div>

        <div className="sm:max-h-[80vh]">{children}</div>
      </div>
    </div>
  );
};
