import {
  WebinarRegistrationFormState,
  noOptionsWorkForMeKey,
} from "./CCWebinarRegistrationModal1";
import moment from "moment-timezone";
import { weekdayMonthDisplayDate } from "@/utils/dates/weekdayMonthDisplay";
import { DateWithType } from "@/utils/schedule/calculateRecurringSchedule";
import { SetFeatureFlags } from "@/types/decisions";
import { useFeatureFlag } from "components/useFeatureFlag";
import formatWebinarDate from "./WebinarDisplayDateFormatting";

const WebinarTimeAndTimezoneInput = ({
  handleChange,
  formState,
  webinarTimeOptions,
  availabilityType,
}: {
  webinarTimeOptions: DateWithType[];
  handleChange: (e: React.ChangeEvent<HTMLSelectElement>) => void;
  formState: WebinarRegistrationFormState;
  availabilityType: SetFeatureFlags["webinarAvailabilityOptions"];
}) => {
  const displayDateFormattingType = useFeatureFlag(
    "registrationDateFormatting",
    {
      fallback: "MonthDateFormatting",
    }
  );
  function TimeOptions() {
    if (!webinarTimeOptions) {
      return null;
    }
    if (displayDateFormattingType === "MonthDateFormatting") {
      return (
        <>
          {webinarTimeOptions.map((option) => (
            <option
              key={option.toString()}
              value={JSON.stringify({
                datetime: option.toUTCString(),
                type: option.type,
              })}
            >
              {weekdayMonthDisplayDate(option)} @{" "}
              {option.toLocaleTimeString([], {
                hour: "numeric",
                minute: "2-digit",
                timeZone: formState.timeZone,
              })}
            </option>
          ))}
        </>
      );
    }
    if (displayDateFormattingType === "TodayTomorrowMonthDayFormatting") {
      return (
        <>
          {webinarTimeOptions.map((option) => (
            <option
              key={option.toString()}
              value={JSON.stringify({
                datetime: option.toUTCString(),
                type: option.type,
              })}
            >
              {formatWebinarDate(option)}
            </option>
          ))}
        </>
      );
    }
    return null;
  }

  return (
    <>
      <div>
        <label className="label-style" htmlFor="dateTime">
          Webinar Time
        </label>
        <select
          className="input-style h-16 text-xl"
          name="startTime"
          onChange={handleChange}
          value={JSON.stringify(formState.startTime)}
        >
          <option
            key={"empty"}
            value={JSON.stringify({ datetime: "", type: "" }) || ""}
            disabled
          >
            Select a time
          </option>
          <TimeOptions />
          {availabilityType === "noneOfTheseWorkOption" && (
            <option
              key={noOptionsWorkForMeKey}
              value={
                JSON.stringify({
                  datetime: "",
                  type: noOptionsWorkForMeKey,
                }) || ""
              }
            >
              None of These Options Work For Me
            </option>
          )}
        </select>
        {formState.startTime.type === noOptionsWorkForMeKey && (
          <p className="text-md font-bold mt-2">
            We will email you with additional times as they are scheduled. Stay
            Tuned!
          </p>
        )}
      </div>
      <div>
        <label className="label-style" htmlFor="timeZone">
          Time Zone
        </label>
        <select
          className="input-style h-16 text-xl"
          name="timeZone"
          onChange={handleChange}
          value={formState.timeZone}
        >
          {moment.tz.names().map((tz) => (
            <option key={tz} value={tz}>
              {tz}
            </option>
          ))}
        </select>
      </div>
    </>
  );
};

export default WebinarTimeAndTimezoneInput;
