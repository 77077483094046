import { Button } from "components/Button";
import Loader from "components/ui/Loader";
import Requires from "internal_components/Requires";
import { WebinarRegistrationFormState } from "./CCWebinarRegistrationModal1";

const WebinarFormSubmitError = ({
  isLoading,
  submitIsDisabled,
  formSubmitError,
  brand,
  formState,
}: {
  isLoading: boolean;
  submitIsDisabled: () => boolean;
  formSubmitError: boolean;
  brand: { name: string };
  formState: WebinarRegistrationFormState;
}) => {
  const generateFormSubmitErrorEmail = () => {
    const generate = () => {
      const newline = "%0D%0A";

      let link = "mailto:support@combinedcuriosity.com";
      link += "?subject=Unable to register for webinar";
      link += `&body=Hello,${
        newline + newline
      } I tried to register for a webinar with ${
        brand.name
      } but was unable. Can you please help me?`;
      link += `${
        newline + newline + newline
      } === Technical Info for our developers === ${newline} ${JSON.stringify(
        formState
      )}`;
      return link;
    };

    // open link in new tab
    window.open(generate(), "_blank");
  };

  return (
    <Requires value={formSubmitError}>
      <div className="bg-white shadow-md rounded rounded-t-none p-8 pt-4 gap-4 flex flex-col">
        <div className="text-center text-lg">
          Uh-oh! There was an error submitting your webinar registration.
        </div>
        <div>
          <a
            className="text-blue-400 cursor-pointer"
            onClick={generateFormSubmitErrorEmail}
            target="_blank"
            rel="noreferrer"
          ></a>
          <div className="flex justify-center mt-6">
            <Loader isLoading={isLoading}>
              <Button
                className={
                  "bg-tertiary hover:bg-tertiary-darker font-bold py-4 px-8 rounded focus:outline-none focus:shadow-outline disabled:bg-gray-400"
                }
                type="submit"
                disabled={submitIsDisabled()}
              >
                Click here to email our support team
              </Button>
              &nbsp;and they&apos;ll make sure you get registered for your
              webinar!
            </Loader>
          </div>
        </div>
      </div>
    </Requires>
  );
};

export default WebinarFormSubmitError;
