import { Event, EventMeta } from "@combined-curiosity/collector-js";
import { inIframe } from "app/checkout-modal/inIframe";
import { createContext, useCallback, useContext, useState } from "react";

declare global {
  interface Window {
    dataLayer?: Record<string, unknown>[];
  }
}

export type trackEvent = (event: Event) => void;

const pushToCollectorQueue = (
  event: Event,
  transport: "beacon" | "xhr"
): void => {
  window.cc = window.cc || {};
  window.cc.event_queue = window.cc.event_queue || [];
  window.cc.event_queue?.push({
    cmd: "track",
    transport,
    data: event,
  });
};

const identify = (identity: { key: string; value: string }): void => {
  window.cc = window.cc || {};
  window.cc.event_queue = window.cc.event_queue || [];
  window.cc.event_queue?.push({
    cmd: "identify",
    data: identity,
  });
};

const pushToEventQueue = (
  event: Event,
  transport: "beacon" | "xhr" = "xhr"
) => {
  window.dataLayer = window.dataLayer || [];

  // switch to notify gtm for particular events
  switch (event.action) {
    case "pageview":
      window.dataLayer.push({
        event: "route-change",
        data: event,
      });
      break;

    case "purchase":
      window.dataLayer.push({
        event: "successful-purchase",
        client_side_unique_id: event.clientSubmissionUniqueId,
        data: JSON.stringify(event),
      });
      break;

    case "webinar_registration": {
      const webinarGtmEvent = {
        action: "webinar_registration",
        meta: {
          ...(event.meta as EventMeta<"webinar_registration">),
          email: event.email,
        },
      };

      window.dataLayer.push({
        event: "webinar registration",
        client_side_unique_id: event.clientSubmissionUniqueId,
        webinar_registration: JSON.stringify(webinarGtmEvent),
      });
      break;
    }

    case "webinar_interaction":
      window.dataLayer.push({
        event: "webinar_interaction",
        email: event.email,
        lead_id: event.leadId,
        client_side_unique_id: event.clientSubmissionUniqueId,
        data: JSON.stringify(event.meta),
      });
      break;

    case "video_interaction":
      window.dataLayer.push({
        event: "video_interaction",
        email: event.email,
        client_side_unique_id: event.clientSubmissionUniqueId,
        data: JSON.stringify(event.meta),
      });
      break;

    case "free_course_signup":
      window.dataLayer.push({
        event: "free_course_signup",
        email: event.email,
        client_side_unique_id: event.clientSubmissionUniqueId,
        data: JSON.stringify(event.meta),
      });
      break;
  }

  pushToCollectorQueue(event, transport);
};

const trackEventViaIframe = (eventName: string, eventProperties: any) => {
  eventProperties = eventProperties || {};

  const payload = { type: eventName, payload: eventProperties };
  try {
    let stringifiedPayload = JSON.stringify(payload);
    window.parent.postMessage(stringifiedPayload, "*");
  } catch {
    window.Rollbar?.error("Failed to stringify payload", payload);
  }
};

export type ITrackingContext = {
  track: (event: Event, transport?: "beacon" | "xhr") => void;
  trackWebinarEvent: (
    meta: EventMeta<"webinar_interaction">,
    uniqueId: string,
    transport?: "beacon" | "xhr"
  ) => void;
  setUserAttribute: (key: keyof Event, value: Event[keyof Event]) => void;
  setShouldUseSendMessageForIframe: (bool: boolean) => void;
};

export const TrackingContext = createContext<ITrackingContext>({
  setUserAttribute: () => {},
  track: () => {},
  trackWebinarEvent: () => {},
  setShouldUseSendMessageForIframe: () => {},
});

export type Track = (event: Event, transport?: "beacon" | "xhr") => void;

export const TrackingWrapper = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [userAttributes, setUserAttributes] = useState<Partial<Event>>({});
  const [shouldUseSendMessageForIframe, setShouldUseSendMessageForIframe] =
    useState(inIframe());

  const track = useCallback(
    (event: Event, transport: "beacon" | "xhr" = "xhr") => {
      if (shouldUseSendMessageForIframe) {
        trackEventViaIframe(event.action, event);
      } else {
        pushToEventQueue(event, transport);
      }
    },
    [shouldUseSendMessageForIframe]
  );

  const setUserAttribute = (key: keyof Event, value: Event[keyof Event]) => {
    setUserAttributes((prevAttributes) => {
      return {
        ...prevAttributes,
        [key]: value,
      };
    });

    const val = value as string;
    // todo: export identity type from collector with
    // defined set of available keys: email, user_id, client_id, + add lead_id
    identify({ key, value: val });
  };

  const trackWebinarEvent = useCallback(
    (
      meta: EventMeta<"webinar_interaction">,
      uniqueId: string,
      transport?: "beacon" | "xhr"
    ) => {
      if (userAttributes.email && userAttributes.leadId) {
        track(
          {
            action: "webinar_interaction",
            meta: {
              ...meta,
              anonymousWebinarViewer: false,
              webinarRegistrationId: "",
            },
            clientSubmissionUniqueId: uniqueId,
            leadId: userAttributes.leadId,
            email: userAttributes.email,
          },
          transport
        );
      }
    },
    [track, userAttributes]
  );

  return (
    <TrackingContext.Provider
      value={{
        setUserAttribute,
        track,
        trackWebinarEvent,
        setShouldUseSendMessageForIframe,
      }}
    >
      {children}
    </TrackingContext.Provider>
  );
};

export const useTracking = (injected?: boolean) => {
  const context = useContext(TrackingContext);
  if (injected || inIframe()) {
    setTimeout(() => {
      context.setShouldUseSendMessageForIframe(true);
    });
  }
  if (context === undefined) {
    throw new Error("useTracking must be used within a TrackingProvider");
  }
  return context;
};

export default pushToEventQueue;
