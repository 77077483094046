import {
  RecurringSchedule,
  daysOfWeek,
  copyDate,
  DaySchedule,
} from "./calculateSchedule";

export const calculateCountdownSchedule = (
  date: Date,
  schedule: RecurringSchedule
) => {
  const currentDayOfWeek = daysOfWeek[date.getDay()];
  const schedules = [];

  // Add previous 7 days in case the countdown starts on a previous day
  for (let i = 7; i > 0; i--) {
    let currentDate = copyDate(date);
    currentDate.setDate(currentDate.getDate() - i);
    const dayOfWeek = daysOfWeek[currentDate.getDay()];
    const dayOfMonth = currentDate.getDate();
    const month = currentDate.getMonth();
    const todaysSchedule = {
      schedule: [...schedule.recurringSchedule.weekly[dayOfWeek]],
      dayOfMonth,
      month,
    } as DaySchedule;
    schedules.push(todaysSchedule);
  }

  const todaysSchedule = {
    schedule: [...schedule.recurringSchedule.weekly[currentDayOfWeek]],
    dayOfMonth: date.getDate(),
    month: date.getMonth(),
  } as DaySchedule;
  schedules.push(todaysSchedule);

  // Add next 7 days in case the countdown ends on a future day
  for (let i = 1; i < 8; i++) {
    let currentDate = copyDate(date);
    currentDate.setDate(currentDate.getDate() + i);
    const dayOfWeek = daysOfWeek[currentDate.getDay()];
    const dayOfMonth = currentDate.getDate();
    const month = currentDate.getMonth();
    const todaysSchedule = {
      schedule: [...schedule.recurringSchedule.weekly[dayOfWeek]],
      dayOfMonth,
      month,
    } as DaySchedule;
    schedules.push(todaysSchedule);
  }

  const normalizedDateTimes = [];

  for (const day of schedules) {
    for (const { start, status } of day.schedule) {
      const startDate = new Date(date);
      startDate.setMonth(day.month);
      startDate.setDate(day.dayOfMonth);

      const [startHour, startMinute] = start.split(":");
      startDate.setHours(+startHour, +startMinute, 0, 0);

      normalizedDateTimes.push({
        start: startDate,
        status: status,
      });
    }
  }

  let currentReturnValue: Date[] = [];

  for (let i = 0; i < normalizedDateTimes.length; i++) {
    const { start, status } = normalizedDateTimes[i];

    if (status === "countdown start") {
      if (start < date) {
        currentReturnValue = [normalizedDateTimes[i + 1].start];
      }
    } else if (status === "countdown end") {
      if (start < date) {
        currentReturnValue = [];
      }
    }
  }

  return currentReturnValue;
};
