import { createContext } from "react";
import { SetFeatureFlags } from "../types";

export type FeatureFlagContextState = {
  featureFlags: SetFeatureFlags;
  evaluatedFeatureFlagsHash: string;
};

export const FeatureFlagContext = createContext<FeatureFlagContextState>({
  featureFlags: {},
  evaluatedFeatureFlagsHash: "",
});
