import { placeholderImageUrlWithText } from "stories/helpers";
import { definitions } from "../../types/supabase";

export const piafData: Array<{
  type: string;
  tags: Array<Tag>;
  value: any;
  consumesResources?: any;
}> = [
  {
    type: "text",
    tags: [{ tone: "congratulations", forPage: "first twenty" }],
    value: "Congratulations! You're one of the first 20 visitors.",
  },
  {
    type: "text",
    tags: [{ action: "enroll in webinar" }],
    value: "Enroll in webinar",
  },
  {
    type: "text",
    tags: [{ tone: "urgency" }],
    value:
      "Don't delay - it's finally time to learn the piano, and we can help",
  },
  { type: "text", tags: [{ tone: "urgency" }], value: "Hurry up and buy now!" },
  {
    type: "text",
    tags: [{ prompt: "when you enroll you will receive" }],
    value: "When you enroll, you'll receive the following",
  },
  {
    type: "image_resource",
    tags: [{ subject: "entire course bundle" }],
    value: placeholderImageUrlWithText(400, 400, "look at this course"),
  },
  {
    type: "text",
    tags: [{ salesProp: "entire bundle" }],
    value: "The entire course bundle",
  },
  {
    type: "text",
    tags: [{ salesProp: "entire bundle" }],
    value: "$200 in savings",
  },

  {
    type: "richtext",
    tags: [{ salesProp: "entire bundle" }, { type: "bullet" }],
    value: "<b>6</b> integrated course books",
  },
  {
    type: "richtext",
    tags: [{ salesProp: "entire bundle" }, { type: "bullet" }],
    value: "<b>2</b> chord charts",
  },
  {
    type: "richtext",
    tags: [{ salesProp: "entire bundle" }, { type: "bullet" }],
    value: "<b>1</b> note guide",
  },

  {
    type: "text",
    tags: [{ salesProp: "bonus on top of bundle" }],
    value: "The entire course bundle",
  },
  {
    type: "text",
    tags: [{ salesProp: "bonus on top of bundle" }],
    value: "$200 in savings",
  },
  {
    type: "richtext",
    tags: [{ salesProp: "bonus on top of bundle" }, { type: "bullet" }],
    value: "<b>6</b> integrated course books",
  },
  {
    type: "richtext",
    tags: [{ salesProp: "bonus on top of bundle" }, { type: "bullet" }],
    value: "<b>2</b> chord charts",
  },
  {
    type: "richtext",
    tags: [{ salesProp: "bonus on top of bundle" }, { type: "bullet" }],
    value: "<b>1</b> note guide",
  },

  {
    type: "text",
    tags: [{ salesProp: "get access immediately" }],
    value: "The entire course bundle",
  },
  {
    type: "text",
    tags: [{ salesProp: "get access immediately" }],
    value: "$200 in savings",
  },
  {
    type: "richtext",
    tags: [{ salesProp: "get access immediately" }, { type: "bullet" }],
    value: "<b>6</b> integrated course books",
  },
  {
    type: "richtext",
    tags: [{ salesProp: "get access immediately" }, { type: "bullet" }],
    value: "<b>2</b> chord charts",
  },
  {
    type: "richtext",
    tags: [{ salesProp: "get access immediately" }, { type: "bullet" }],
    value: "<b>1</b> note guide",
  },
  {
    type: "richtext",
    tags: [
      { type: "bullet" },
      { tone: "ease of learning" },
      { valueProposition: "start immediately" },
    ],
    value: "Start immediately",
  },
  {
    type: "text",
    tags: [{ tone: "you can too" }],
    value:
      'What if we told you that everything you think you know about learning to play the piano may not be true? Scott "The Piano Guy" Houston has taught tens of thousands of adults how to play non-classical piano through his unique method, and you\'re next.',
  },
  {
    type: "text",
    tags: [{ tone: "explanation" }],
    value: "What the webinar will teach you: ",
  },
  {
    type: "text",
    tags: [{ prompt: "start today" }],
    value:
      "Today is the day - start your piano playing journey and join Scott in his free webinar",
  },
  {
    type: "text",
    tags: [{ prompt: "act now" }],
    value:
      'Click the button below to register for a day and time to join Scott "The Piano Guy" Houston in his FREE piano webinar - it\'s YOUR turn to play!',
  },
  { type: "text", tags: [{ type: "copyright text" }], value: "PianoInAFlash" },
  {
    type: "richtext",
    tags: [{ prompt: "course accolades" }],
    value: "<b>35,000</b> students",
  },
  {
    type: "richtext",
    tags: [{ prompt: "course accolades" }],
    value: "<b>6x Emmy</b> award winning teacher",
  },
  {
    type: "richtext",
    tags: [{ prompt: "course accolades" }],
    value: "<b>30 day</b> Money Back Guarantee",
  },
  {
    type: "text",
    tags: [{ tone: "urgency" }],
    value:
      "Claim your exclusive webinar only bonus and save $440 - limited time",
  },
  {
    type: "text",
    tags: [{ prompt: "when you enroll you will receive" }],
    value: "When you enroll, you immediately get",
  },
  {
    type: "text",
    tags: [{ prompt: "immediately get" }],
    value: "$200 discount, access to 400+ lessons, 30 day money back guarantee",
  },
  {
    type: "offer",
    tags: [{ product: "full course", paymentType: "single payment" }],
    value: {
      msrpPrice: "$600",
      offerPrice: "$134",
      paymentPrompt: "one payment of",
      ctaText: "Claim bonus & enroll",
    },
  },
  {
    type: "offer",
    tags: [{ product: "full course", paymentType: "monthly payments " }],
    value: {
      msrpPrice: "$600",
      offerPrice: "$12",
      paymentPrompt: "14 monthly payments of",
      ctaText: "Claim bonus & enroll",
    },
  },
  {
    type: "offer",
    tags: [{ product: "full course", paymentType: "single payment" }],
    value: {
      msrpPrice: "$600",
      offerPrice: "$134",
      paymentPrompt: "one payment of",
      ctaText: "Claim bonus & enroll",
    },
  },
  {
    type: "text",
    tags: [{ type: "privacy policy url" }],
    value: "https://pianoinaflash.com/privacy",
  },
  {
    type: "text",
    tags: [{ prompt: "testimonial header" }],
    value: "Hear from real students who finally took the leap",
  },
  {
    type: "text",
    tags: [{ prompt: "testimonial number of students" }],
    value: "35,000 students, 35,000 new music makers",
  },
  {
    type: "text",
    tags: [{ prompt: "start today" }, { prompt: "creator accolades" }],
    value:
      'After teaching hundreds of piano workshops throughout the country, hosting his very own "Piano Guy" TV show on PBS, and launching his international online Piano in a Flash learning environment, Scott truly has taught tens of thousands of adults how to play non-classical piano in days and weeks, not months and years. Here is a sneak peek at some of the "Secrets" Scott uses in his teaching.',
  },
  {
    type: "imageTitleAndText",
    tags: [{ type: "webinar team" }],
    value: { name: "Scott Houston", title: "Webinar Host" },
    consumesResources: [
      {
        type: "image_resource",
        inject_as: "image",
        value: "//v.fastcdn.co/u/e8e57313/34326116-0-scott-with-emmys-102.png",
      },
    ],
  },
  {
    type: "imageTitleAndText",
    tags: [{ type: "webinar team" }],
    value: { name: "Theresa", title: "Moderator" },
    consumesResources: [
      {
        type: "image_resource",
        inject_as: "image",
        value: "//v.fastcdn.co/u/e8e57313/51912378-0-TK-Pic-for-Sophie---.jpg",
      },
    ],
  },
  {
    type: "imageTitleAndText",
    tags: [{ type: "webinar team" }],
    value: { name: "Natalie", title: "Moderator" },
    consumesResources: [
      {
        type: "image_resource",
        inject_as: "image",
        value: "//v.fastcdn.co/u/e8e57313/53803381-0-IMG-5184.JPG",
      },
    ],
  },
  {
    type: "imageTitleAndText",
    tags: [{ type: "webinar team" }],
    value: { name: "Emily", title: "Moderator" },
    consumesResources: [
      {
        type: "image_resource",
        inject_as: "image",
        value: "//v.fastcdn.co/u/e8e57313/51783029-0-headshot.jpg",
      },
    ],
  },
  {
    type: "imageTitleAndText",
    tags: [{ type: "testimonial" }],
    value: {
      image: "resource_id:456",
      title: "C. Tipps",
      text: "I have learned so much from you. I can actually play a few songs now! God bless! Thanks for opening the door to learning to play the piano.",
    },
    consumesResources: [
      {
        type: "image_resource",
        inject_as: "image",
        value: "//v.fastcdn.co/u/e8e57313/55673247-0-testimonial-image-4.jpg",
      },
    ],
  },
  {
    type: "imageTitleAndText",
    tags: [{ type: "testimonial" }],
    value: {
      title: "K. Mahon",
      text: "A million thanks—you introduced me to piano, and I haven't looked back. 4 years later, and I'm still going strong. I’ve gone from not knowing the keys or note names to playing full tilt boogie and blues.",
    },
    consumesResources: [
      {
        type: "image_resource",
        inject_as: "image",
        value: "//v.fastcdn.co/u/e8e57313/55673257-0-testimonial-image-8.jpg",
      },
    ],
  },
  {
    type: "imageTitleAndText",
    tags: [{ type: "testimonial" }],
    value: {
      title: "B. Gonzales",
      text: "First off, my family is blown away by how well I am playing! I am really glad I came across your product. Great job & Thank you!",
    },
    consumesResources: [
      {
        type: "image_resource",
        inject_as: "image",
        value: "//v.fastcdn.co/u/e8e57313/55663587-0-testimonial-image-3.jpg",
      },
    ],
  },
  {
    type: "text",
    tags: [{ prompt: "what is stopping you?" }],
    value: "What has kept you from becoming a music maker?",
  },
  {
    type: "text",
    tags: [
      { prompt: "what is stopping you?" },
      { prompt: "start the journey" },
    ],
    value:
      "Did you try as a kid but get bored? Or possibly have a bad experience with a teacher? What about losing interest because you didn't like the music you were learning? Do you just keep telling yourself, \"I'll get to it eventually?\" No matter your reason, know that by visiting this page, you've taken the first step in a journey that can change your life and finally turn you into a music maker. Hear about Scott Houston, our 6x time Emmy winning teacher, and his life changing method by watching the video below...",
  },
  {
    type: "text",
    tags: [
      { tone: "ease of learning" },
      { valueProposition: "start immediately" },
    ],
    value:
      'How traditional methods overcomplicate the learning process\
  Why any adult, no matter their background, can learn\
  How Scott\'s "3 Secrets" can get you playing in days, not months',
  },
  {
    type: "image_resource",
    tags: [{ size: "logo" }],
    value: "//v.fastcdn.co/u/e8e57313/55581551-0-new-logo-black-no-ta.png",
  },
  {
    type: "image_resource",
    tags: [{ type: "endorser logo" }],
    value: "//v.fastcdn.co/u/e8e57313/56027441-0-mens-health-logo-lig.png",
  },
  {
    type: "image_resource",
    tags: [{ type: "endorser logo" }],
    value: "//v.fastcdn.co/u/e8e57313/56027436-0-pbs-logo-light-green.png",
  },
  {
    type: "image_resource",
    tags: [{ type: "endorser logo" }],
    value: "//v.fastcdn.co/u/e8e57313/56027516-0-Emmys-light-green.png",
  },
  {
    type: "image_resource",
    tags: [{ type: "endorser logo" }],
    value: "//v.fastcdn.co/u/e8e57313/56027426-0-good-morning-america.png",
  },
  {
    type: "image_resource",
    tags: [{ type: "endorser logo" }],
    value:
      "https://v.fastcdn.co/u/e8e57313/56027511-0-abc-logo-light-green.png",
  },
  {
    type: "image_resource",
    tags: [{ display: "background" }, { subject: "embodiment of the course" }],
    value:
      "https://v.fastcdn.co/u/e8e57313/52384240-0-close-up-of-piano-ke.jpg",
  },
  {
    type: "image_resource",
    tags: [{ subject: "person doing the activity" }],
    value: "https://v.fastcdn.co/u/e8e57313/62096795-0-bwpiano.png",
  },
  {
    type: "video_resource",
    tags: [{ forPage: "webinar landing page" }],
    value: "http://placekitten.com/600/600",
  },
  {
    type: "questionAndAnswer",
    tags: [{ type: "faq" }, { type: "webinar faq" }],
    value: {
      question: "I'm not very tech savvy - what is a webinar?",
      answer:
        "A webinar in its simplest form is a seminar conducted over the internet. It’s pretty simple - Instead of watching someone talk to you on your TV or in person, you are simply watching them talk to you over your computer, tablet, or smartphone. In this case, you’ll be watching Scott. What makes a webinar fun though is that while you’re watching, you have the ability to comment and ask questions live, and Scott’s webinar Moderators will answer them for you!",
    },
  },
  {
    type: "questionAndAnswer",
    tags: [{ type: "faq" }, { type: "webinar faq" }],
    value: {
      question: "What if I want to learn how to play classical piano?",
      answer:
        "If learning how to play classical piano is your goal, then this webinar is not for you. Scott strictly teaches through a non-classical, modern piano style, that focuses on genres such as jazz, pop, country, rock, etc.",
    },
  },
];

export const mopData = [
  { type: "text", tags: [{ mood: "happy" }], value: "I love painting!" },
  {
    type: "text",
    tags: [{ mood: "sad" }],
    value: "I'm so sad without painting in my life...",
  },
  {
    type: "text",
    tags: [{ mood: "happy" }, { mood: "surprised" }],
    value: "I couldn't believe how much I loved painting!",
  },
  {
    type: "text",
    tags: [{ valueProposition: "money-value" }],
    value: "Gasoline is expensive - use oil based paint!",
  },
  {
    type: "image",
    tags: [{ numberOfPeople: "multiple-people" }],
    value: "http://placebear.com/600/600",
  },
];

type SizeTag = { size: "logo" };
type ActionTag = { action: "enroll in webinar" };
type ToneTag = {
  tone:
    | "urgency"
    | "you can too"
    | "ease of learning"
    | "explanation"
    | "congratulations"
    | "limited time offer"
    | "call user to action"
    | "do this to get an incentive";
};
type TypeTag = {
  type:
    | "bullet"
    | "endorser logo"
    | "webinar team"
    | "testimonial"
    | "faq"
    | "webinar faq"
    | "copyright text"
    | "privacy policy url"
    | "everwebinarId"
    | "webinar embed";
};
type ValuePropositionTag = { valueProposition: "start immediately" };
type PromptTag = {
  prompt:
    | "what is stopping you?"
    | "start the journey"
    | "start today"
    | "creator accolades"
    | "testimonial header"
    | "testimonial number of students"
    | "act now"
    | "course accolades"
    | "when you enroll you will receive"
    | "immediately get"
    | "why you would want to get incentive"
    | "how to get incentive"
    | "never too late";
};
type ForPageTag = {
  forPage: "webinar landing page" | "first twenty" | "black friday early bird";
};
type SubjectTag = {
  subject:
    | "embodiment of the course"
    | "person doing the activity"
    | "entire course bundle";
};
type ProductTag = { product: "full course" };
type PaymentTypeTag = { paymentType: "single payment" | "monthly payments " };
type ImageDisplayTag = { display: "background" };
type SalesPropTag = {
  salesProp:
    | "entire bundle"
    | "bonus on top of bundle"
    | "get access immediately";
};
type CTATag = { cta: "explain primary cta" };

type Tag =
  | SizeTag
  | ActionTag
  | ToneTag
  | TypeTag
  | ValuePropositionTag
  | PromptTag
  | ForPageTag
  | ImageDisplayTag
  | SubjectTag
  | ProductTag
  | PaymentTypeTag
  | SalesPropTag
  | CTATag;

export type ComponentDefinition = {
  type: string;
  version: number;
  required_data: {
    [propName: string]: ComponentRequiredData;
  };
  metadata?: {
    id?: string;
    hidden?: boolean;
  };
};

type ResourceType = definitions["resources"]["type"];
export type ComponentRequiredData = {
  type: ResourceType;
  tags: Array<Tag>;
  retrieveAll?: boolean;
  exampleQuantity?: number;
  metadata?: {
    onClick: string;
    onClickArgs?: string;
  };
  rules?: {
    minLength?: number;
    maxLength?: number;
  };
};

type Template = {
  name: string;
  components: Array<ComponentDefinition>;
};

export const ThreeSecretsWebinar: Template = {
  name: "3 Secrets Webinar",
  components: [
    {
      type: "WebinarRegistrationModal",
      version: 1,
      required_data: {
        webinarId: {
          type: "text",
          tags: [{ type: "everwebinarId" }],
        },
        webinarEmebedCode: {
          type: "text",
          tags: [{ type: "webinar embed" }],
        },
      },
      metadata: {
        id: "webinar-registration-modal",
        hidden: true,
      },
    },
    {
      type: "NavbarCTA",
      version: 1,
      required_data: {
        logo: {
          type: "image_resource",
          tags: [{ size: "logo" }],
        },
        ctaText: {
          type: "text",
          tags: [{ action: "enroll in webinar" }],
          metadata: {
            onClick: "showModal",
            onClickArgs: JSON.stringify({ id: "webinar-registration-modal" }),
          },
        },
      },
    },
    {
      type: "Banner",
      version: 2,
      required_data: {
        text: {
          type: "text",
          tags: [],
        },
      },
    },
    {
      type: "HeaderLeftTextWithPictureAndCTA",
      version: 1,
      required_data: {
        backgroundImage: {
          type: "image_resource",
          tags: [{ subject: "embodiment of the course" }],
        },
        title: {
          type: "text",
          tags: [{ tone: "urgency" }],
          rules: { minLength: 60, maxLength: 120 }, // do we do this?
        },
        description: {
          type: "text",
          tags: [{ tone: "you can too" }],
        },
        bulletListTitle: {
          type: "text",
          tags: [{ tone: "explanation" }],
        },
        bullets: {
          type: "richtext",
          tags: [
            { type: "bullet" },
            { tone: "ease of learning" },
            { valueProposition: "start immediately" },
          ],
          retrieveAll: true,
          exampleQuantity: 3,
        },
        ctaText: {
          type: "text",
          tags: [{ action: "enroll in webinar" }],
          metadata: {
            onClick: "showModal",
            onClickArgs: JSON.stringify({ id: "webinar-registration-modal" }),
          },
        },
      },
    },
    {
      type: "EndorserLogos",
      version: 1,
      required_data: {
        images: {
          type: "image_resource",
          tags: [{ type: "endorser logo" }],
          retrieveAll: true,
          exampleQuantity: 5,
        },
      },
    },
    {
      type: "TextBlockWithTitle",
      version: 1,
      required_data: {
        title: {
          type: "text",
          rules: { minLength: 500 },
          tags: [{ prompt: "what is stopping you?" }],
        },
        textBlock: {
          type: "text",
          tags: [
            { prompt: "what is stopping you?" },
            { prompt: "start the journey" },
          ],
        },
      },
    },
    {
      type: "Video",
      version: 1,
      required_data: {
        video: {
          type: "video_resource",
          tags: [{ forPage: "webinar landing page" }],
        },
      },
    },
    {
      type: "TextBlockWithTitle",
      version: 1,
      required_data: {
        title: {
          type: "text",
          tags: [{ prompt: "start today" }],
        },
        textBlock: {
          type: "text",
          tags: [{ prompt: "start today" }, { prompt: "creator accolades" }],
        },
      },
    },
    {
      type: "CTA",
      version: 1,
      required_data: {
        buttonText: {
          type: "text",
          tags: [{ action: "enroll in webinar" }],
          metadata: {
            onClick: "showModal",
            onClickArgs: JSON.stringify({ id: "webinar-registration-modal" }),
          },
        },
      },
    },
    {
      type: "HorizontalRule",
      version: 1,
      required_data: {},
    },
    {
      type: "SmallImageWithTitleAndSubtitle",
      version: 1,
      required_data: {
        imageTitleAndText: {
          type: "imageTitleAndText",
          tags: [{ type: "webinar team" }],
          retrieveAll: true,
          exampleQuantity: 4,
        },
      },
    },
    {
      type: "HorizontalRule",
      version: 1,
      required_data: {},
    },
    {
      type: "TextBlockWithTitle",
      version: 1,
      required_data: {
        title: {
          type: "text",
          tags: [{ prompt: "testimonial header" }],
        },
        textBlock: {
          type: "text",
          tags: [{ prompt: "testimonial number of students" }],
        },
      },
    },
    {
      type: "CardWithImageTitleAndText",
      version: 1,
      required_data: {
        imageTitleAndText: {
          type: "imageTitleAndText",
          tags: [{ type: "testimonial" }],
          retrieveAll: true,
          exampleQuantity: 3,
        },
      },
    },
    {
      type: "CTABanner",
      version: 1,
      required_data: {
        backgroundImage: {
          type: "image_resource",
          tags: [{ display: "background" }],
        },
        title: {
          type: "text",
          tags: [{ prompt: "start today" }],
        },
        text: {
          type: "text",
          tags: [{ prompt: "act now" }],
        },
        ctaButtonText: {
          type: "text",
          tags: [{ action: "enroll in webinar" }],
          metadata: {
            onClick: "showModal",
            onClickArgs: JSON.stringify({ id: "webinar-registration-modal" }),
          },
        },
      },
    },
    {
      type: "FAQ",
      version: 1,
      required_data: {
        questionAndAnswer: {
          type: "questionAndAnswer",
          tags: [{ type: "faq" }, { type: "webinar faq" }],
          retrieveAll: true,
          exampleQuantity: 10,
        },
      },
    },
    {
      type: "CTA",
      version: 1,
      required_data: {
        buttonText: {
          type: "text",
          tags: [{ action: "enroll in webinar" }],
          metadata: {
            onClick: "showModal",
            onClickArgs: JSON.stringify({ id: "webinar-registration-modal" }),
          },
        },
      },
    },
    {
      type: "Footer",
      version: 1,
      required_data: {
        logo: {
          type: "image_resource",
          tags: [{ size: "logo" }],
        },
        copyrightText: {
          type: "text",
          tags: [{ type: "copyright text" }],
        },
        privacyPolicyUrl: {
          type: "text",
          tags: [{ type: "privacy policy url" }],
        },
      },
    },
  ],
};

export const onClickActions: { [key: string]: Function } = {
  consoleLog: (emitEvent: any, args: any) => console.log("clicked!", args),
  showModal: (emitEvent: any, args: any) => {
    emitEvent("showModal", args);
  },
};

export const FirstTwenty: Template = {
  name: "First 20 LP",
  components: [
    {
      type: "Banner",
      version: 1,
      required_data: {
        text: {
          type: "text",
          tags: [{ tone: "congratulations", forPage: "first twenty" }],
        },
      },
    },
    {
      type: "SplitPurchaseHeader",
      version: 1,
      required_data: {
        backgroundImage: {
          type: "image_resource",
          tags: [{ subject: "person doing the activity" }],
        },
        logo: {
          type: "image_resource",
          tags: [{ size: "logo" }],
        },
        title: {
          type: "text",
          tags: [{ prompt: "act now", tone: "limited time offer" }],
        },
        subtitle: {
          type: "text",
          tags: [{ prompt: "when you enroll you will receive" }],
        },
        bullets: {
          type: "richtext",
          tags: [{ type: "bullet" }, { prompt: "immediately get" }],
          retrieveAll: true,
          exampleQuantity: 3,
        },
        singlePaymentOffer: {
          type: "offer",
          tags: [{ product: "full course" }, { paymentType: "single payment" }],
          metadata: {
            onClick: "consoleLog",
          },
        },
        installmentsOffer: {
          type: "offer",
          tags: [
            { product: "full course" },
            { paymentType: "monthly payments " },
          ],
          metadata: {
            onClick: "consoleLog",
          },
        },
      },
    },
    {
      type: "EndorserLogos",
      version: 1,
      required_data: {
        images: {
          type: "image_resource",
          tags: [{ type: "endorser logo" }],
          retrieveAll: true,
          exampleQuantity: 5,
        },
      },
    },
    {
      type: "HorizontalBullets",
      version: 1,
      required_data: {
        bullets: {
          type: "richtext",
          tags: [{ type: "bullet" }, { prompt: "course accolades" }],
          retrieveAll: true,
          exampleQuantity: 3,
        },
      },
    },
    {
      type: "EnrollOfferingChart",
      version: 1,
      required_data: {
        title: {
          type: "text",
          tags: [{ prompt: "when you enroll you will receive" }],
        },
        image: {
          type: "image_resource",
          tags: [{ subject: "entire course bundle" }],
        },
        section1Title: {
          type: "text",
          tags: [{ salesProp: "entire bundle" }],
        },
        section1Subtitle: {
          type: "text",
          tags: [{ salesProp: "entire bundle" }],
        },
        section1Bullets: {
          type: "richtext",
          tags: [{ type: "bullet" }, { salesProp: "entire bundle" }],
          retrieveAll: true,
          exampleQuantity: 3,
        },
        section2Title: {
          type: "text",
          tags: [{ salesProp: "bonus on top of bundle" }],
        },
        section2Subtitle: {
          type: "text",
          tags: [{ salesProp: "bonus on top of bundle" }],
        },
        section2Bullets: {
          type: "richtext",
          tags: [{ type: "bullet" }, { salesProp: "bonus on top of bundle" }],
          retrieveAll: true,
          exampleQuantity: 3,
        },
        section3Title: {
          type: "text",
          tags: [{ salesProp: "get access immediately" }],
        },
        section3Subtitle: {
          type: "text",
          tags: [{ salesProp: "get access immediately" }],
        },
        section3Bullets: {
          type: "richtext",
          tags: [{ type: "bullet" }, { salesProp: "get access immediately" }],
          retrieveAll: true,
          exampleQuantity: 3,
        },
      },
    },
  ],
};

export const BlackFridayEarlyBird: Template = {
  name: "Black Friday Early Bird",
  components: [
    {
      type: "NavbarOnlyLogo",
      version: 1,
      required_data: {
        logo: {
          type: "image_resource",
          tags: [{ size: "logo" }],
        },
      },
    },
    {
      type: "Banner",
      version: 2,
      required_data: {
        text: {
          type: "text",
          tags: [{ cta: "explain primary cta" }],
        },
      },
    },
    {
      type: "HeaderWithSideImageAndCTA",
      version: 1,
      required_data: {
        image: {
          type: "image_resource",
          tags: [{ subject: "embodiment of the course" }],
        },
        title: {
          type: "text",
          tags: [
            { tone: "do this to get an incentive" },
            { tone: "call user to action" },
            { forPage: "black friday early bird" },
          ],
        },
        text: {
          type: "richtext",
          tags: [
            { prompt: "why you would want to get incentive" },
            { forPage: "black friday early bird" },
          ],
        },
        subtitle: {
          type: "text",
          tags: [{ prompt: "how to get incentive" }],
        },
        bullets: {
          type: "richtext",
          tags: [{ type: "bullet" }, { forPage: "black friday early bird" }],
          retrieveAll: true,
          exampleQuantity: 3,
        },
        ctaText: {
          type: "text",
          tags: [{ forPage: "black friday early bird" }],
        },
      },
    },
    {
      type: "TitleWithImages",
      version: 1,
      required_data: {
        title: {
          type: "text",
          tags: [{ prompt: "when you enroll you will receive" }],
        },
        images: {
          type: "image_resource",
          tags: [{ tone: "you can too" }],
          retrieveAll: true,
          exampleQuantity: 3,
        },
      },
    },
    {
      type: "ImageWithTitleAndText",
      version: 1,
      required_data: {
        image: {
          type: "image_resource",
          tags: [{ subject: "entire course bundle" }],
        },
        title: {
          type: "text",
          tags: [],
        },
        text: {
          type: "text",
          tags: [],
        },
      },
    },
    {
      type: "ImageWithTitleAndTextAndCTA",
      version: 1,
      required_data: {
        image: {
          type: "image_resource",
          tags: [{ subject: "entire course bundle" }],
        },
        title: {
          type: "text",
          tags: [{ tone: "urgency" }],
        },
        text: {
          type: "text",
          tags: [{ prompt: "never too late" }],
        },
        ctaText: {
          type: "text",
          tags: [{ tone: "urgency" }],
          metadata: {
            onClick: "consoleLog",
          },
        },
      },
    },
  ],
};
