type weekdayFormatType = "short" | "long" | "narrow" | undefined;
/*
  2024-09-01T12:30:00.000Z returns Sun, September 1 at 8:30 AM
  2024-09-01T12:00:00.000Z returns Sun, September 1 at 8 AM
*/
export const weekdayMonthDisplayDate = (
  date: Date | null | undefined,
  weekdayFormat: weekdayFormatType = "short"
) => {
  if (!date) return null;

  const weekday = date.toLocaleString("default", { weekday: weekdayFormat });
  const month = date.toLocaleString("default", { month: "long" });

  return weekday + ", " + month + " " + date.getDate();
};

export const weekdayMonthDisplayDateTime = (
  date: Date | null | undefined,
  weekdayFormat: weekdayFormatType = "short"
) => {
  if (!date) return null;

  const time = date.toLocaleTimeString([], {
    hour: "numeric",
    minute: date.getMinutes() > 0 ? "2-digit" : undefined,
  });

  return weekdayMonthDisplayDate(date, weekdayFormat) + " at " + time;
};
