"use client";
import React, { ReactNode } from "react";

export function Button({
  children,
  className,
  disabled = false,
  link,
  onClick,
  type = "button",
}: {
  /** a node to be rendered in the special component. */
  children?: ReactNode;
  /** the tailwind classes for the button */
  className?: string;
  disabled?: boolean;
  /** the type of button behavior */
  type?: "button" | "submit" | "reset";
  /** the link the button will direct the user to */
  link?: string;
  /** action for button to run if there is no link */
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
}) {
  function buttonClick(e: React.MouseEvent<HTMLButtonElement>) {
    if (link) {
      window.location.href = link;
    } else if (onClick && e) {
      onClick(e);
    }
  }

  return (
    <button
      disabled={disabled}
      aria-label={typeof children === "string" ? children : "button"}
      className={`cursor-pointer ${className}`}
      onClick={buttonClick}
      type={type}
    >
      {children}
    </button>
  );
}
