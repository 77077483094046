// takes given string and makes it a valid color ie: 'rgb(900 900 900)' should be rgb(255 255 255)

import tinycolor from "tinycolor2";

// so custom colors can save appropriately for contrasting text on top of them
export const validateColor = (strColor: string | undefined) => {
  if (!strColor) return "";
  var s = new Option().style;
  s.color = strColor;
  return s.color;
};

const componentToHex = (c: number) => {
  var hex = c?.toString(16);
  return hex?.length == 1 ? "0" + hex : hex;
};

const rgbToHex = (r: number, g: number, b: number) => {
  return "#" + componentToHex(r) + componentToHex(g) + componentToHex(b);
};

// prop can be 'rgb(255 255 255)' or '255 255 255'
export const convertToHex = (color: string | undefined) => {
  if (color) {
    let rgb = color;
    if (rgb.substring(0, 4) === "rgb(") {
      rgb = rgb.slice(4);
      rgb = rgb.substring(0, rgb.length - 1);
    }
    const [r, g, b] = rgb.split(" ").map((x) => parseInt(x));
    return rgbToHex(r, g, b);
  }
};

export const convertHexToRGB = (hex: string) => {
  var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  const color = result
    ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16),
      }
    : null;

  return color ? `${color.r} ${color.g} ${color.b}` : "";
};

export const calculateDarkerColor = (color: string, amt: number) => {
  return tinycolor(convertToHex(color)).darken(amt);
};

export const calculateContrastingColor = (color: string) => {
  return tinycolor(convertToHex(color)).isLight() ? "#000000" : "#ffffff";
};
