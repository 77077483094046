import { calculateCountdownSchedule } from "./calculateCountdownSchedule";
import { calculateRecurringSchedule } from "./calculateRecurringSchedule";

export type DayOfWeek =
  | "monday"
  | "tuesday"
  | "wednesday"
  | "thursday"
  | "friday"
  | "saturday"
  | "sunday";
export type Hour =
  | 0
  | 1
  | 2
  | 3
  | 4
  | 5
  | 6
  | 7
  | 8
  | 9
  | 10
  | 11
  | 12
  | 13
  | 14
  | 15
  | 16
  | 17
  | 18
  | 19
  | 20
  | 21
  | 22
  | 23;
export type FifteenMinute = 0 | 15 | 30 | 45;

export type ScheduleEntry =
  | {
      start: string;
      status: "scheduled" | "countdown end" | "countdown start";
    }
  | {
      start: string;
      end: string;
      status: "JIT";
      frequency: string;
    };

export type WeeklySchedule = {
  [key in DayOfWeek]: Array<ScheduleEntry>;
};

export type ScheduleType = {
  singleDateTime?: Date;
  recurringSchedule?: {
    weekly: WeeklySchedule;
  };
  options: {
    mode: "recurring" | "single" | "countdown";
    inTimezone: "local" | "EST";
    totalDatesToDisplay: number;
  };
};

export type RecurringSchedule = Required<
  Omit<ScheduleType, "singleDateTime">
> & { options: ScheduleType["options"] & { mode: "recurring" } };

export type DaySchedule = {
  schedule: RecurringSchedule["recurringSchedule"]["weekly"][DayOfWeek];
  month: number;
  dayOfMonth: number;
};

export const daysOfWeek: DayOfWeek[] = [
  "sunday",
  "monday",
  "tuesday",
  "wednesday",
  "thursday",
  "friday",
  "saturday",
];

export const copyDate = (date: Date) => {
  return new Date(date);
};

const calculateSchedule = (
  date: Date,
  schedule: ScheduleType | null,
  timezone: string
) => {
  if (!schedule) return null;
  if (schedule.options.mode === "countdown") {
    return calculateCountdownSchedule(date, schedule as RecurringSchedule);
  } else if (schedule.options.mode === "recurring") {
    return calculateRecurringSchedule(date, schedule, timezone);
  } else {
    return schedule.singleDateTime ? [schedule.singleDateTime] : [];
  }
};

export default calculateSchedule;
