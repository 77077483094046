import { useContext, useEffect, useState } from "react";
import { SetFeatureFlags } from "@/types/decisions";
import { FeatureFlagContext } from "components/FeatureFlagContext";

// This generates the options for a flag that are not null or undefined
export type OptionsForFlag<TFlag extends keyof SetFeatureFlags> = {
  fallback: NonNullable<SetFeatureFlags[TFlag]>;
};
export const useFeatureFlag = <TFlag extends keyof SetFeatureFlags>(
  flag: TFlag,
  options: OptionsForFlag<TFlag>
) => {
  const featureFlagContext = useContext(FeatureFlagContext);

  // We set evaluatedFeatureFlagValue to be a known, not-null and not-undefined value
  // if we didn't have the fallback, we couldn't make that assertion.
  // But, because we force the developer to provide a client-side fallback in any instance
  // where we do not get a server-side feature flag (for whatever reason), we can
  // still write declarative code at the component level because we are sure to have
  // _a_ valid value for the given feature flag.
  const [evaluatedFeatureFlagValue, setEvaluatedFeatureFlagValue] = useState<
    NonNullable<SetFeatureFlags[TFlag]>
  >(options.fallback);

  // look for overrides in URL
  useEffect(() => {}, [flag]);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const urlFlag = urlParams.get(flag);
    // allow overwriting feature flag in URL for testing
    if (urlFlag) {
      setEvaluatedFeatureFlagValue(
        urlFlag as NonNullable<SetFeatureFlags[TFlag]>
      );
    } else {
      setEvaluatedFeatureFlagValue(
        featureFlagContext.featureFlags[flag] || options.fallback
      );
    }
  }, [featureFlagContext.featureFlags, flag, options.fallback]);

  return evaluatedFeatureFlagValue;
};
