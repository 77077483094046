import { Button } from "components/Button";
import Loader from "components/ui/Loader";

const WebinarRegistrationSubmitButton = ({
  isLoading,
  submitIsDisabled,
  buttonText,
}: {
  isLoading: boolean;
  submitIsDisabled: () => boolean;
  buttonText: string;
}) => {
  return (
    <Loader isLoading={isLoading}>
      <Button
        className={
          "bg-tertiary hover:bg-tertiary-darker text-white font-bold py-4 px-8 rounded focus:outline-none focus:shadow-outline disabled:bg-gray-400"
        }
        type="submit"
        disabled={submitIsDisabled()}
      >
        {buttonText}
      </Button>
    </Loader>
  );
};

export default WebinarRegistrationSubmitButton;
