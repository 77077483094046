import { createContext, useContext, useEffect, useRef } from "react";
import {
  ComponentDefinition,
  onClickActions,
} from "../../utils/cms/fakeSupabaseData";

export const usePageEventListener = (eventName: string, callback: any) => {
  const isAdded = useRef(false);
  const context = useContext(PageContext);
  const { addEventListener } = context;

  useEffect(() => {
    if (!isAdded.current) {
      addEventListener(eventName, callback);
      isAdded.current = true;
    }
  }, [addEventListener, eventName, callback]);
};

export const useClickHandler = (
  component: ComponentDefinition,
  propName: string
) => {
  const context = useContext(PageContext);
  const { emitEvent } = context;
  useEffect(() => {
    // this makes emitEvent available on the window to help with debugging
    (window as any).emitEvent = emitEvent;
  }, [emitEvent]);

  if (!component) {
    return (): null => null;
  }

  const metadata = component.required_data[propName]?.metadata;

  if (metadata) {
    const { onClick, onClickArgs } = metadata;
    return () => {
      return onClickActions[onClick](emitEvent, onClickArgs);
    };
  } else {
    return () => {
      return null;
    };
  }
};

export const PageContext = createContext<any>({});
