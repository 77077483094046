import { FaSpinner } from "react-icons/fa";
import { ReactNode } from "react";

const Loader = ({
  children,
  isLoading,
}: {
  children: ReactNode;
  isLoading: boolean;
}) => {
  return (
    <>
      {isLoading ? (
        <div className="flex justify-center items-center">
          <FaSpinner className="animate-spin w-6 md:w-8 h-6 md:h-8" />
        </div>
      ) : (
        children
      )}
    </>
  );
};

export default Loader;
