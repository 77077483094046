import { CMSImage } from "components/CMSImage";
import { CMSImageResource } from "components/cms/types/cmsTypes";

const WebinarRegistrationFormHeader = ({
  image,
  brand,
  webinarBrandPersonaName,
}: {
  image: CMSImageResource;
  brand: { name: string };
  webinarBrandPersonaName: string;
}) => {
  return (
    <>
      <CMSImage
        className="w-24 h-24 rounded-full shadow-lg
            hidden sm:block absolute top-0 left-1/2 transform -translate-x-1/2 -translate-y-1/2"
        imgClassName="rounded-full w-24 h-24 object-cover border-white border-4"
        image={image}
        alt={brand.name}
      />

      <div className="w-full flex-col justify-center bg-primary rounded-t h-40 pt-2 sm:h-24 hidden sm:flex">
        <CMSImage
          className="block sm:hidden self-center w-24 h-24 rounded-full shadow-lg"
          imgClassName="rounded-full w-24 h-24 object-cover border-white border-4"
          image={image}
          alt={brand.name}
        />
        <div className="w-full h-full flex items-end justify-center text-white pb-4 font-bold">
          {webinarBrandPersonaName}
        </div>
      </div>
    </>
  );
};

export default WebinarRegistrationFormHeader;
