import {
  WebinarRegistrationFormError,
  WebinarRegistrationFormState,
} from "./CCWebinarRegistrationModal1";

const WebinarNameInput = ({
  handleChange,
  formError,
  formState,
  setFormError,
}: {
  handleChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  formError: WebinarRegistrationFormError;
  formState: WebinarRegistrationFormState;
  setFormError: React.Dispatch<
    React.SetStateAction<WebinarRegistrationFormError>
  >;
}) => {
  const isValidName = () => {
    return formState.name.length > 0;
  };

  const validateName = () => {
    if (!isValidName()) {
      setFormError({ ...formError, name: true });
    } else {
      setFormError({ ...formError, name: false });
    }
  };

  return (
    <div>
      <label className="label-style" htmlFor="name">
        First Name
      </label>
      <input
        onBlur={validateName}
        className={`${
          formError.name ? "input-style-error" : "input-style"
        } h-16 text-xl`}
        id="name"
        type="text"
        autoCapitalize="off"
        placeholder="First Name"
        onChange={handleChange}
        name="name"
      />
    </div>
  );
};

export default WebinarNameInput;
