const placeholderImageUrlWithText = (
  width: number,
  height: number,
  text: string
) => {
  return (
    `https://via.placeholder.com/${width}x${height}/808080/000000/?text=` + text
  );
};

export { placeholderImageUrlWithText };
