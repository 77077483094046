export const isValidEmail = (email = "") => {
  // Enhanced email validation regex
  const emailRegex =
    /^[a-zA-Z0-9._%+-]+(\+[a-zA-Z0-9._%+-]+)?[^.]@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

  // Additional checks
  const isValidFormat = emailRegex.test(email);

  return isValidFormat;
};
